const MAX_VAR_LENGTH = 63;

export const VAR_BODY_REG_EXP_PATTERN = `[^%<>&\\s][^%&<>\n]{0,${MAX_VAR_LENGTH}}`;
export const VAR_REGEXP_PATTERN = `%${VAR_BODY_REG_EXP_PATTERN}%`;

export const VAR_REG_EXP_WORD = new RegExp(`^${VAR_REGEXP_PATTERN}$`);
export const VAR_REG_EXP_GLOBALLY = new RegExp(VAR_REGEXP_PATTERN, 'g');

export const VAR_BODY_REG_EXP_WORD = new RegExp(
  `^${VAR_BODY_REG_EXP_PATTERN}$`
);

export const VAR_BODY_OLD_REG_EXP_PATTERN = `[^%\\s][^%]{0,${MAX_VAR_LENGTH}}`;
export const VAR_OLD_REGEXP_PATTERN = `%${VAR_BODY_OLD_REG_EXP_PATTERN}%`;
