import * as React from 'react';
import ReactDOM from 'react-dom/client';

import { Permission } from 'shared/entities/permissions';
import { ChannelKind } from 'shared/entities/channels';

import initSentry from './scripts/initSentry';
import getCurrentDomain from './scripts/getCurrentDomain';
import Root from './App/pages/Root';

export const CURRENT_DOMAIN = getCurrentDomain();
export const PROD_EN_DOMAIN = 'smartbotpro.io';

import './scss/main.scss';
import './scss/new/main.scss';

initSentry();

const container = document.getElementById('app');
const root = ReactDOM.createRoot(container);

root.render(
  <Root
    plugins={{
      internationalDomain: {
        turnOn: CURRENT_DOMAIN === PROD_EN_DOMAIN
      },
      modules: {
        clustering: false,
        instruction: true
      },
      managers: {
        notConsideredPermissions: [
          Permission.CREATE_CABINET,
          Permission.PUBLISH_SCENARIOS
        ]
      },
      channels: {
        hiddenChannels: [ChannelKind.IG, ChannelKind.FB],
        extraChannels: {
          order: [],
          entities: {}
        }
      },
      blocks: {
        excludeBlocks: []
      }
    }}
  />
);

if (module.hot) {
  module.hot.accept();
}
