import {
  HandleProductPageModeParams,
  InitialProductPageModeParams,
  MainProductOverviewModeParams,
  ShopbackHandleCategoryParams
} from 'shared/entities/shopback';

import { Bucket } from '../bucket';
import { IntegrationTab } from '../integrations';
import { MailingStatusGroup } from '../mailing';
import { ScenarioKind } from '../scenario';

import { stringifyParams } from './utils';

export enum UtmTag {
  utmSource = 'utm_source',
  utmMedium = 'utm_medium',
  utmCampaign = 'utm_campaign',
  utmTerm = 'utm_term',
  utmContent = 'utm_content'
}

export enum AuthStage {
  CABINET_NOT_FOUND = 'cabinet_not_found',
  CABINET_CREATION = 'cabinet_creation',

  ACCEPT_INVITATION = 'accept_invitation',
  REQUEST_PASSWORD_RECOVER = 'request_password_recover',
  PASSWORD_RECOVER = 'password_recover',

  EMAIL_UNSUBSCRIBE = 'email_unsubscribe',

  ERROR = 'error'
}

export enum TemplateQuery {
  IS_TEMPLATE = 'is_template'
}

export enum ProjectUnit {
  SCENARIOS = 'scenarios',
  CLUSTERING = 'clustering',
  USERS = 'users',
  SCENARIO = 'scenario',
  SETTINGS = 'settings',
  STATS = 'stats',
  MAILINGS = 'mailings',
  INTEGRATIONS = 'integrations',
  CONVERSATIONS = 'conversations',
  GROWTH_TOOLS = 'growth_tools',
  SMARTBOT_AI = 'smartbot_ai',
  ONBOARDING = 'onboarding',
  SMARTBOT_AI_BOTS = 'smartbot_ai_bots',
  CHANNELS = 'channels'
}

export enum VariablesTypeParam {
  SPECIAL = 'special',
  GLOBAL = 'global',
  LOCAL = 'local'
}

export enum CabinetSettingsTabs {
  BALANCE = 'balance',
  MANAGERS = 'managers',
  COMMON = 'common',
  NOT_AVAILABLE = 'not_available',
  DOCUMENTS = 'documents',
  NO_NECESSARY_PERMISSIONS = 'no_necessary_permissions',
  REFERRAL = 'referral'
}

export enum SettingsUnit {
  CABINET = 'cabinet',
  PROFILE = 'profile'
}

export enum SmartbotAITabs {
  EDUCATION = 'education',
  CONNECTION = 'connection',
  INSTRUCTION = 'instruction'
}

export enum ProjectSettingsUnit {
  common = 'common',
  managers = 'managers'
}

export enum UsersTabs {
  TABLE = 'table',
  LISTS = 'lists',
  LIST = 'list'
}

export enum AuthOpeningBaseParams {
  from = 'from',
  promo = 'promo',
  open = 'open'
}

export type AuthOpeningParams = UtmTag | AuthOpeningBaseParams;

export enum AuthFromParamReservedValue {
  oauth = 'oauth'
}

export type AuthOpeningFromParamValue = AuthFromParamReservedValue | string;

export type AuthOpeningParamsStructure = Partial<
  {
    from: AuthOpeningFromParamValue;
  } & Record<Exclude<AuthOpeningParams, 'from'>, string | null>
>;

export const authOpeningParams = [
  ...Object.values(UtmTag),
  ...Object.values(AuthOpeningBaseParams)
];

export enum AuthOpeningOpenParam {
  shopback = 'shopback',
  smartbot_ai = 'smartbot_ai'
}

export enum EntityTypes {
  LINK = 'link',
  BLOCK = 'block'
}

export enum GrowthToolsPages {
  SHOPS = 'shops',
  CREATE_SHOP = 'create_shop',
  HANDLE_CATEGORY = 'handle_category',
  CREATE_INITIAL_PRODUCT = 'create_initial_product',
  HANDLE_PRODUCT = 'handle_product',
  OVERVIEW_PRODUCT = 'overview_product',
  PRODUCT = 'product',
  SHOP = 'shop',
  UPLOAD_PRODUCTS = 'upload_products',
  INSTRUCTION = 'instruction'
}

export enum GrowthToolsInstructionUnit {
  TEMPLATE = 'template',
  SCENARIO = 'scenario'
}

export enum GrowthToolsShopsUnit {
  FORM = 'form'
}

export enum GrowthToolsShopUnit {
  SETTINGS = 'settings',
  APP_PRODUCTS = 'app_products',
  CONNECTION = 'connection'
}

export const urls = {
  ROOT: {
    mask: '/'
  },
  AUTH: {
    root: '/auth',
    mask: '/auth/:stage?',
    create: (authStage: AuthStage): string => `/auth/${authStage}/`
  },
  PROJECT: {
    root: '/project',
    mask: '/project/:projectId/:projectUnit/',
    create: ({
      projectId,
      projectUnit
    }: {
      projectId: string;
      projectUnit: ProjectUnit;
    }): string => `/project/${projectId}/${projectUnit}/`,
    tabs: {
      INTEGRATIONS: {
        mask: `/project/:projectId/${ProjectUnit.INTEGRATIONS}/:integrationTab`,
        create: ({
          projectId,
          integrationTab
        }: {
          projectId: string;
          integrationTab: IntegrationTab;
        }): string =>
          `/project/${projectId}/${ProjectUnit.INTEGRATIONS}/${integrationTab}`,
        INTEGRATION: {
          mask: ({
            integrationTab
          }: {
            integrationTab: IntegrationTab;
          }): string =>
            `/project/:projectId/${ProjectUnit.INTEGRATIONS}/${integrationTab}`
        }
      },
      MAILINGS: {
        mask: `/project/:projectId/${ProjectUnit.MAILINGS}`,
        create: ({
          projectId,
          mailingStatus
        }: {
          projectId: string;
          mailingStatus: MailingStatusGroup;
        }): string =>
          `/project/${projectId}/${ProjectUnit.MAILINGS}/${mailingStatus}`,
        GENERAL: {
          mask: `/project/:projectId/${ProjectUnit.MAILINGS}/${MailingStatusGroup.general}`,
          create: ({ projectId }: { projectId: string }): string =>
            `/project/${projectId}/${ProjectUnit.MAILINGS}/${MailingStatusGroup.general}`
        },
        DRAFT: {
          mask: `/project/:projectId/${ProjectUnit.MAILINGS}/${MailingStatusGroup.draft}`,
          create: ({ projectId }: { projectId: string }): string =>
            `/project/${projectId}/${ProjectUnit.MAILINGS}/${MailingStatusGroup.draft}`
        },
        PLANNED: {
          mask: `/project/:projectId/${ProjectUnit.MAILINGS}/${MailingStatusGroup.planned}`,
          create: ({ projectId }: { projectId: string }): string =>
            `/project/${projectId}/${ProjectUnit.MAILINGS}/${MailingStatusGroup.planned}`
        }
      },
      CONVERSATIONS: {
        mask: `/project/:projectId/${ProjectUnit.CONVERSATIONS}/:conversationId?`,
        create: ({
          projectId,
          conversationId
        }: {
          projectId: string;
          conversationId?: string;
        }): string => {
          if (!conversationId) {
            return `/project/${projectId}/${ProjectUnit.CONVERSATIONS}`;
          }
          return `/project/${projectId}/${ProjectUnit.CONVERSATIONS}/${conversationId}`;
        }
      },
      GROWTH_TOOLS: {
        mask: `/project/:projectId/${ProjectUnit.GROWTH_TOOLS}/:page`,
        CREATE_SHOP: {
          mask: `/project/:projectId/${ProjectUnit.GROWTH_TOOLS}/${GrowthToolsPages.CREATE_SHOP}`,
          create: ({
            projectId,
            shopId
          }: {
            projectId: string;
            shopId?: number;
          }): string => {
            return `/project/${projectId}/${ProjectUnit.GROWTH_TOOLS}/${
              GrowthToolsPages.CREATE_SHOP
            }${
              shopId
                ? `/?${stringifyParams({
                    shopId
                  })}`
                : ''
            }`;
          }
        },
        HANDLE_CATEGORY: {
          mask: `/project/:projectId/${ProjectUnit.GROWTH_TOOLS}/${GrowthToolsPages.HANDLE_CATEGORY}/:shopId`,
          create: ({
            projectId,
            params
          }: {
            projectId: string;
            params: ShopbackHandleCategoryParams;
          }): string => {
            const { shopId, ...rest } = params;
            return `/project/${projectId}/${ProjectUnit.GROWTH_TOOLS}/${
              GrowthToolsPages.HANDLE_CATEGORY
            }/${shopId}?${stringifyParams({
              ...rest
            })}`;
          }
        },
        HANDLE_PRODUCT: {
          mask: `/project/:projectId/${ProjectUnit.GROWTH_TOOLS}/${GrowthToolsPages.HANDLE_PRODUCT}/:shopId`,
          create: (
            props: {
              projectId: string;
              shopId: number;
            } & HandleProductPageModeParams
          ): string => {
            const { projectId, shopId, ...rest } = props;

            return `/project/${projectId}/${ProjectUnit.GROWTH_TOOLS}/${
              GrowthToolsPages.HANDLE_PRODUCT
            }/${shopId}?${stringifyParams(rest)}`;
          }
        },
        OVERVIEW_PRODUCT: {
          mask: `/project/:projectId/${ProjectUnit.GROWTH_TOOLS}/${GrowthToolsPages.OVERVIEW_PRODUCT}/:shopId`,
          create: (
            props: {
              projectId: string;
              shopId: number;
            } & MainProductOverviewModeParams
          ): string => {
            const { projectId, shopId, ...rest } = props;

            return `/project/${projectId}/${ProjectUnit.GROWTH_TOOLS}/${
              GrowthToolsPages.OVERVIEW_PRODUCT
            }/${shopId}?${stringifyParams(rest)}`;
          }
        },
        CREATE_INITIAL_PRODUCT: {
          mask: `/project/:projectId/${ProjectUnit.GROWTH_TOOLS}/${GrowthToolsPages.CREATE_INITIAL_PRODUCT}/:shopId/:categoryId`,
          create: (
            props: {
              projectId: string;
              shopId: number;
            } & InitialProductPageModeParams
          ): string => {
            const { projectId, shopId, categoryId, mode, productType } = props;
            return `/project/${projectId}/${ProjectUnit.GROWTH_TOOLS}/${
              GrowthToolsPages.CREATE_INITIAL_PRODUCT
            }/${shopId}/${categoryId}?${stringifyParams({
              mode,
              productType
            })}`;
          }
        },
        SHOPS: {
          mask: `/project/:projectId/${ProjectUnit.GROWTH_TOOLS}/${GrowthToolsPages.SHOPS}`,
          create: ({ projectId }: { projectId: string }): string => {
            return `/project/${projectId}/${ProjectUnit.GROWTH_TOOLS}/${GrowthToolsPages.SHOPS}`;
          }
        },
        PRODUCT: {
          mask: `/project/:projectId/${ProjectUnit.GROWTH_TOOLS}/${GrowthToolsPages.PRODUCT}/:productId?`,
          create: ({
            projectId,
            productId
          }: {
            projectId: string;
            productId?: string;
          }): string => {
            return `/project/${projectId}/${ProjectUnit.GROWTH_TOOLS}/${GrowthToolsPages.PRODUCT}/${productId}`;
          }
        },
        SHOP: {
          mask: `/project/:projectId/${ProjectUnit.GROWTH_TOOLS}/${GrowthToolsPages.SHOP}/:shopId`,
          create: ({
            projectId,
            shopId,
            params = null
          }: {
            projectId: string;
            shopId: number;
            params?: {
              openCategoryId?: number;
              reload?: boolean;
            } | null;
          }): string => {
            return `/project/${projectId}/${ProjectUnit.GROWTH_TOOLS}/${
              GrowthToolsPages.SHOP
            }/${shopId}/${
              params
                ? `?${stringifyParams({
                    ...params
                  })}`
                : ''
            }`;
          },
          SETTINGS: {
            mask: `/project/:projectId/${ProjectUnit.GROWTH_TOOLS}/${GrowthToolsPages.SHOP}/:shopId/${GrowthToolsShopUnit.SETTINGS}`,
            create: ({
              projectId,
              shopId
            }: {
              projectId: string;
              shopId: number;
            }): string => {
              return `/project/${projectId}/${ProjectUnit.GROWTH_TOOLS}/${GrowthToolsPages.SHOP}/${shopId}/${GrowthToolsShopUnit.SETTINGS}`;
            }
          },
          CONNECTION: {
            mask: `/project/:projectId/${ProjectUnit.GROWTH_TOOLS}/${GrowthToolsPages.SHOP}/:shopId/${GrowthToolsShopUnit.CONNECTION}`,
            create: ({
              projectId,
              shopId
            }: {
              projectId: string;
              shopId: number;
            }): string => {
              return `/project/${projectId}/${ProjectUnit.GROWTH_TOOLS}/${GrowthToolsPages.SHOP}/${shopId}/${GrowthToolsShopUnit.CONNECTION}`;
            }
          }
        }
      },
      SMARTBOT_AI: {
        mask: `/project/:projectId/${ProjectUnit.SMARTBOT_AI}/:tab/:botId?`,
        create: ({
          projectId,
          botId,
          tab
        }: {
          projectId: string;
          botId?: string | null;
          tab: SmartbotAITabs;
        }): string =>
          `/project/${projectId}/${ProjectUnit.SMARTBOT_AI}/${tab}/${
            botId ?? ''
          }`,
        createTabMask: ({ tab }: { tab: SmartbotAITabs }): string => {
          return `/project/:projectId/${ProjectUnit.SMARTBOT_AI}/${tab}/:botId?`;
        }
      },

      SMARTBOT_AI_BOTS: {
        mask: `/project/:projectId/${ProjectUnit.SMARTBOT_AI_BOTS}`,
        create: ({ projectId }: { projectId: string }): string =>
          `/project/${projectId}/${ProjectUnit.SMARTBOT_AI_BOTS}`
      },

      SCENARIOS: {
        mask: `/project/:projectId/${ProjectUnit.SCENARIOS}/:kind`,
        COMMON: {
          mask: `/project/:projectId/${ProjectUnit.SCENARIOS}/${ScenarioKind.common}`,
          create: ({ projectId }: { projectId: string }): string =>
            `/project/${projectId}/${ProjectUnit.SCENARIOS}/${ScenarioKind.common}`
        },
        REACTIONS: {
          mask: `/project/:projectId/${ProjectUnit.SCENARIOS}/${ScenarioKind.reaction}`,
          create: ({ projectId }: { projectId: string }): string =>
            `/project/${projectId}/${ProjectUnit.SCENARIOS}/${ScenarioKind.reaction}`
        }
      },
      CLUSTERING: {
        mask: `/project/:projectId/${ProjectUnit.CLUSTERING}`,
        create: ({ projectId }: { projectId: string }): string =>
          `/project/${projectId}/${ProjectUnit.CLUSTERING}`
      },
      SETTINGS: {
        mask: `/project/:projectId/${ProjectUnit.SETTINGS}/:unit`,
        COMMON: {
          mask: `/project/:projectId/${ProjectUnit.SETTINGS}/${ProjectSettingsUnit.common}`,
          create: ({ projectId }: { projectId: string }): string =>
            `/project/${projectId}/${ProjectUnit.SETTINGS}/${ProjectSettingsUnit.common}`
        },
        MANAGERS: {
          mask: `/project/:projectId/${ProjectUnit.SETTINGS}/${ProjectSettingsUnit.managers}`,
          create: ({ projectId }: { projectId: string }): string =>
            `/project/${projectId}/${ProjectUnit.SETTINGS}/${ProjectSettingsUnit.managers}`
        }
      },
      USERS: {
        mask: `/project/:projectId/${ProjectUnit.USERS}/:type`,
        TABLE: {
          mask: `/project/:projectId/${ProjectUnit.USERS}/${UsersTabs.TABLE}`,
          create: ({ projectId }: { projectId: string }): string =>
            `/project/${projectId}/${ProjectUnit.USERS}/${UsersTabs.TABLE}`
        },
        LISTS: {
          mask: `/project/:projectId/${ProjectUnit.USERS}/${UsersTabs.LISTS}`,
          create: ({ projectId }: { projectId: string }): string =>
            `/project/${projectId}/${ProjectUnit.USERS}/${UsersTabs.LISTS}`
        },
        LIST: {
          mask: `/project/:projectId/${ProjectUnit.USERS}/${UsersTabs.LIST}/:id`,
          create: ({
            projectId,
            usersListId
          }: {
            projectId: string;
            usersListId: string;
          }): string =>
            `/project/${projectId}/${ProjectUnit.USERS}/${UsersTabs.LIST}/${usersListId}`
        }
      },
      SCENARIO: {
        mask: `/project/:projectId/${ProjectUnit.SCENARIO}/:scenarioId/:entityType?/:entityId?`,
        create: ({
          projectId,
          scenarioId,
          bucket,
          from
        }: {
          projectId: string;
          scenarioId: string;
          bucket: Bucket;
          from?: string | null;
        }): string =>
          `/project/${projectId}/${
            ProjectUnit.SCENARIO
          }/${scenarioId}?${stringifyParams({
            bucket,
            from: from || undefined
          })}`,
        EDIT: {
          mask: `/project/:projectId/${ProjectUnit.SCENARIO}/:scenarioId/:entityType/:entityId`,
          create: ({
            projectId,
            scenarioId,
            entityId,
            entityType,
            bucket,
            from
          }: {
            projectId: string;
            scenarioId: string;
            bucket: Bucket;
            entityType: EntityTypes;
            entityId: string;
            from?: string | null;
          }): string =>
            `/project/${projectId}/${
              ProjectUnit.SCENARIO
            }/${scenarioId}/${entityType}/${entityId}?${stringifyParams({
              bucket,
              from: from || undefined
            })}`
        }
      },
      STATS: {
        mask: `/project/:projectId/${ProjectUnit.STATS}`,
        create: ({ projectId }: { projectId: string }): string =>
          `/project/${projectId}/${ProjectUnit.STATS}`
      },
      ONBOARDING: {
        mask: `/project/:projectId/${ProjectUnit.ONBOARDING}`,
        create: ({ projectId }: { projectId: string }): string =>
          `/project/${projectId}/${ProjectUnit.ONBOARDING}`
      },
      CHANNELS: {
        mask: `/project/:projectId/${ProjectUnit.CHANNELS}`,
        create: ({ projectId }: { projectId: string }): string =>
          `/project/${projectId}/${ProjectUnit.CHANNELS}`
      }
    }
  },
  SETTINGS: {
    root: '/settings',
    mask: '/settings/:unit?',
    CABINET: {
      mask: `/settings/${SettingsUnit.CABINET}/:type?`,
      BALANCE: {
        mask: `/settings/${SettingsUnit.CABINET}/${CabinetSettingsTabs.BALANCE}`,
        create: (): string =>
          `/settings/${SettingsUnit.CABINET}/${CabinetSettingsTabs.BALANCE}`
      },
      MANAGERS: {
        mask: `/settings/${SettingsUnit.CABINET}/${CabinetSettingsTabs.MANAGERS}`,
        create: (): string =>
          `/settings/${SettingsUnit.CABINET}/${CabinetSettingsTabs.MANAGERS}`
      },
      COMMON: {
        mask: `/settings/${SettingsUnit.CABINET}/${CabinetSettingsTabs.COMMON}`,
        create: (): string =>
          `/settings/${SettingsUnit.CABINET}/${CabinetSettingsTabs.COMMON}`
      },
      NOT_AVAILABLE: {
        mask: `/settings/${SettingsUnit.CABINET}/${CabinetSettingsTabs.NOT_AVAILABLE}`,
        create: (): string =>
          `/settings/${SettingsUnit.CABINET}/${CabinetSettingsTabs.NOT_AVAILABLE}`
      },
      NO_NECESSARY_PERMISSIONS: {
        mask: `/settings/${SettingsUnit.CABINET}/${CabinetSettingsTabs.NO_NECESSARY_PERMISSIONS}`,
        create: (): string =>
          `/settings/${SettingsUnit.CABINET}/${CabinetSettingsTabs.NO_NECESSARY_PERMISSIONS}`
      },
      DOCUMENTS: {
        mask: `/settings/${SettingsUnit.CABINET}/${CabinetSettingsTabs.DOCUMENTS}`,
        create: (): string =>
          `/settings/${SettingsUnit.CABINET}/${CabinetSettingsTabs.DOCUMENTS}`
      },
      REFERRAL: {
        mask: `/settings/${SettingsUnit.CABINET}/${CabinetSettingsTabs.REFERRAL}`,
        create: (): string =>
          `/settings/${SettingsUnit.CABINET}/${CabinetSettingsTabs.REFERRAL}`
      }
    },
    PROFILE: {
      mask: `/settings/${SettingsUnit.PROFILE}`,
      create: (): string => `/settings/${SettingsUnit.PROFILE}`
    }
  },
  SUBSCRIPTION: {
    root: '/subscription',
    mask: '/subscription'
  }
};
