import * as React from 'react';
import Highlighter from 'react-highlight-words';

import { TextHighlighterProps } from 'shared/entities/components/TextHighliter';

const TextHighlighter: React.FC<TextHighlighterProps> = ({
  text,
  query = [],
  caseSensitive = true,
  className
}: TextHighlighterProps) => {
  // ошибка внутри либы, ругается на символы
  const actualQuery = query?.reduce((acc, item) => {
    try {
      new RegExp(item);

      return [...acc, item];
    } catch (e) {
      return acc;
    }
  }, []);

  return (
    <Highlighter
      highlightClassName="bold-text"
      className={className}
      textToHighlight={text}
      searchWords={actualQuery}
      caseSensitive={caseSensitive}
    />
  );
};

export default React.memo(TextHighlighter);
