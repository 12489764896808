import * as React from 'react';

import BaseIcon from 'shared/components/icons/BaseIcon';
import { IconSize, CommonIconProps } from 'shared/entities/components/Icon';

const RatingIcon: React.FC<CommonIconProps> = (props) => {
  return (
    <BaseIcon
      svgProps={{
        viewBox: '0 0 24 24'
      }}
      iconSize={IconSize.L}
      {...props}
    >
      <path
        d="M22 7H16.333V4C16.333 3.73478 16.2276 3.48043 16.0401 3.29289C15.8526 3.10536 15.5982 3 15.333 3H8.667C8.40178 3 8.14743 3.10536 7.95989 3.29289C7.77236 3.48043 7.667 3.73478 7.667 4V11H2C1.73478 11 1.48043 11.1054 1.29289 11.2929C1.10536 11.4804 1 11.7348 1 12V20C1 20.2652 1.10536 20.5196 1.29289 20.7071C1.48043 20.8946 1.73478 21 2 21H22C22.2652 21 22.5196 20.8946 22.7071 20.7071C22.8946 20.5196 23 20.2652 23 20V14V8C23 7.73478 22.8946 7.48043 22.7071 7.29289C22.5196 7.10536 22.2652 7 22 7ZM7.667 20H5H2V18V16V14V13V12H5H7.667V20ZM15.3315 8V13.9744V20H8.667V11.8595V4H12H15.3315V8ZM22 20H19H16.333V8H19H20.5H22V13.9744V20Z"
        fill="currentColor"
      />
    </BaseIcon>
  );
};

export default RatingIcon;
