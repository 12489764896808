import { observer } from 'mobx-react';
import * as React from 'react';

import { TranslationNode } from 'shared/entities/localization';
import { TextHighlighterProps } from 'shared/entities/components/TextHighliter';
import { useTranslationsStore } from 'stores/index';

import TextHighlighter from '../TextHighlighter';

type Props = {
  children: TranslationNode;
  textHighLiter?: Omit<TextHighlighterProps, 'text'>;
};

const TransComp: React.FC<Props> = ({ children, textHighLiter }) => {
  const store = useTranslationsStore();
  if (typeof children === 'function') {
    return (
      <>
        {!textHighLiter ? (
          children(store.localization.t)
        ) : (
          <TextHighlighter
            text={children(store.localization.t)}
            {...textHighLiter}
          />
        )}
      </>
    );
  }

  if (typeof children === 'string' && textHighLiter) {
    return <TextHighlighter text={children} {...textHighLiter} />;
  }

  return <>{children}</>;
};

export default observer(TransComp);
