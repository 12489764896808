import * as React from 'react';

import { CommonIconProps, IconSize } from 'shared/entities/components/Icon';
import BaseIcon from 'shared/newComponents/icons/BaseIcon';

type Props = CommonIconProps & {
  strokeWidth: number;
};

const AwardCupBaseIcon: React.FC<Props> = (props: Props) => {
  const { strokeWidth, ...rest } = props;
  return (
    <BaseIcon
      iconSize={IconSize.XXM}
      svgProps={{
        viewBox: '0 0 22 22'
      }}
      {...rest}
    >
      <path
        d="M11 14C7.68629 14 5 11.3137 5 8V2.44444C5 2.0306 5 1.82367 5.06031 1.65798C5.16141 1.38021 5.38021 1.16141 5.65798 1.06031C5.82367 1 6.0306 1 6.44444 1H15.5556C15.9694 1 16.1763 1 16.342 1.06031C16.6198 1.16141 16.8386 1.38021 16.9397 1.65798C17 1.82367 17 2.0306 17 2.44444V8C17 11.3137 14.3137 14 11 14ZM11 14V17M17 3H19.5C19.9659 3 20.1989 3 20.3827 3.07612C20.6277 3.17761 20.8224 3.37229 20.9239 3.61732C21 3.80109 21 4.03406 21 4.5V5C21 5.92997 21 6.39496 20.8978 6.77646C20.6204 7.81173 19.8117 8.62038 18.7765 8.89778C18.395 9 17.93 9 17 9M5 3H2.5C2.03406 3 1.80109 3 1.61732 3.07612C1.37229 3.17761 1.17761 3.37229 1.07612 3.61732C1 3.80109 1 4.03406 1 4.5V5C1 5.92997 1 6.39496 1.10222 6.77646C1.37962 7.81173 2.18827 8.62038 3.22354 8.89778C3.60504 9 4.07003 9 5 9M6.44444 21H15.5556C15.801 21 16 20.801 16 20.5556C16 18.5919 14.4081 17 12.4444 17H9.55556C7.59188 17 6 18.5919 6 20.5556C6 20.801 6.19898 21 6.44444 21Z"
        stroke="currentColor"
        strokeWidth={`${strokeWidth}`}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseIcon>
  );
};

export const AwardCupIcon: React.FC<CommonIconProps> = (props) => (
  <AwardCupBaseIcon strokeWidth={2} {...props} />
);

export const AwardCupThinIcon: React.FC<CommonIconProps> = (props) => (
  <AwardCupBaseIcon strokeWidth={1} {...props} />
);
